.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 5px;
  margin: 0 0 25px;
  font-weight: 400;
  font-size: 16px;
  align-items: center;
  span {
    line-height: 38px !important;
  }
}

.disabled {
  color: rgba(113, 125, 150, 1)
}

.type {
  text-transform: capitalize;
}

.metricValueWrapper {
  display: flex;
  gap: 25px;
  align-items: center;

  :global(.MuiFormControlLabel-labelPlacementStart){
    margin-left: 0 !important;
  }

  .input {
    width: 120px;
  }
}

.campaignBudget {
  :global(.MuiInputAdornment-positionEnd){
    p {
      transform: translate(-3px, 1px);
    }
  }
}

.predicted {
  &_input {
    width: 130px;
  }
}
