@import '_variables.scss';
@import '_nullifying';
:root {
  --fc-event-resizer-thickness: 20px;
  --fc-button-bg-color: var(--joy-palette-neutral-800);
  --fc-small-font-size: 0.9em;
  --fc-event-text-color: #0a0a0a;
  //color: var(--joy-palette-neutral-800);

  //color: var( --joy-palette-neutral-800) !important;
}

.fc .fc-timeline-slot-cushion {
  //padding: 0
}

.fc-theme-standard {
  //background-color: var(--main-content-bg);
  background-color: #fff;
  //
  //font-size: 14px !important;
}

.fc-timeline-header-row {
  border-bottom: 1px solid var(--fc-neutral-bg-color);
}
.fc-scrollgrid-section-header {
  overflow: hidden !important;
}

.fc-scrollgrid-sync-inner {
  .fc-icon {
    width: 0 !important;
  }
}

.fc-datagrid-expander {
  display: none !important;
}

tr[role='row'] {
  th[role='columnheader'] {
    &:first-child {
      display: none;
    }
  }
}

.event-title {
  height: 40px;
  background-color: rgba(217, 217, 217, 1);
  //border: 1px solid #0a0a0a;;
  border-color: #000;
  //border-collapse: collapse;
  cursor: pointer;
}

.event-subTitle {
  height: 40px;
  background-color: rgba(217, 217, 217, 1);
  border-color: #000;
}

.event-data {
  height: 40px;
  //border-left: 1px solid #000;
  background-color: rgba(79, 79, 79, 0);
}

.event-total {
  background-color: inherit;
  height: 40px;
}

.fc-timeline-event:not(.fc-event-end):after,
.fc-timeline-event:not(.fc-event-start):before {
  display: none !important;
}
//.fc-event-main{
//  &:before {
//    //content: none;
//    display: none !important;
//  }
//  &:after {
//    display: none;
//  }
//}
.fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events {
  padding: 0 !important;
}
.fc-timeline-event {
  min-height: 100% !important;
}

.fc-event {
  margin: 0 !important;
}

.fc-timeline-event {
  padding: 0 !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin: 0.5em;
}
.fc-button-group {
  gap: 10px;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child),
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  border-radius: 8px;
}

.planning-title {
  height: 40px;
  background-color: #d9d9d9;
  border-color: #000;
  color: #0a0a0a;

  i {
    color: #0a0a0a;
  }
}

.fc-event-main {
  text-align: center;
  height: 100%;
  width: 100%;
  line-height: 38px;
  font-size: 13px;
  //padding: 9px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  i {
    color: #0a0a0a;
  }
}

//.fc-event-draggable {
//  resize: horizontal;
//  cursor: w-resize !important;
//}

.fc-timeline-event-harness:has(> .fc-event-draggable) {
  //border-left: 1px solid #000;
  //border-collapse: collapse;
  //&:after {
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  right: 0;
  //  width: 2px;
  //  height: 100%;
  //  resize: horizontal;
  //  overflow: hidden;
  //  //border: 1px solid black;
  //  cursor: w-resize;
  //  z-index: 20;
  //}
}

.fc-h-event {
  border-color: #000;
}

.fc-theme-standard td,
.fc-theme-standard th {
  //border-bottom: 1px solid var(--fc-neutral-bg-color);
  border-bottom: none;
  border-top: none;
}
.fc-timeline-slot-frame {
  border-left: 1px solid var(--fc-neutral-bg-color);
}

tr:has(> td > .fc-timeline-lane-frame > .fc-timeline-events > .fc-timeline-event-harness > .event-end) {
  //border-bottom: 2px solid var(--fc-neutral-bg-color);
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--fc-neutral-bg-color);
  }
}

tr:has(> td > .fc-timeline-lane-frame > .fc-timeline-events > .fc-timeline-event-harness > .footer) {
  border-top: 0px solid var(--fc-neutral-bg-color) !important;
  position: relative;
  //&:before {
  //  content: ' ';
  //  position: absolute;
  //  bottom: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 2px;
  //  background-color: var(--fc-neutral-bg-color);
  //}
}

tr:has(> td > .fc-timeline-lane-frame > .fc-timeline-events > .fc-timeline-event-harness > .event-total) {
  background-color: $gray-dark-bg;
}

table {
  //border-collapse: collapse;
}
.fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end {
  cursor: w-resize;
  left: 0;
  right: -50px;
}

.fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-start {
  cursor: e-resize;
  right: 0;
  left: -50px;
}

// Resize

.hiddenCopyBlock {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(79, 79, 79, 0.07);
}

.preResize {
  resize: horizontal;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 220%;
  //cursor: w-resize;
  //z-index: 100;
  background-color: inherit;
}

.resizer {
  background-color: rgba(79, 79, 79, 0.07);
}

//.resizable { background: cyan; position: relative; }
.resizer {
  width: 4px;
  height: 100%;
  background: blue;
  position: absolute;
  left: 100%;
  bottom: 0;
  cursor: w-resize;
  //z-index: 101;
}

.resource-header {
  background-color: rgba(217, 217, 217, 0.38);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 1);
  }

  &:after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 1);
  }

  .fc-datagrid-cell-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    //height: 100px !important;
  }
}

.resource-headerChannelsWrapper {
  //background-color: rgba(217, 217, 217, 0.38);
  vertical-align: middle !important;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 1) !important;
  .fc-datagrid-cell-frame {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //position: relative;
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 1);
  }

  &:after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 1);
  }
}

.resource-headerChannels {
  background-color: rgba(217, 217, 217, 0.38);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  .fc-datagrid-cell-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 1);
  }

  &:after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 1);
  }
}

.resource-subHeader {
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(217, 217, 217, 0.85);
  }
  &-end {
    //padding-bottom: 2px !important;
    &:after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 1);
    }
  }
  &-footer {
    //padding-bottom: 1px !important;
    background-color: $gray-dark-bg;
  }
}

.resource-subHeader {
  line-height: 1.1rem;
  font-size: 0.95em;
  .fc-datagrid-cell-frame {
    display: flex;
    align-items: center;
    .fc-datagrid-cell-cushion .fc-scrollgrid-sync-inner {
      padding: 0 !important;
    }
    .fc .fc-datagrid-cell-cushion {
      padding: 0 !important;
    }
  }
}

.fc .fc-datagrid-cell-cushion {
  padding: 0 8px !important;
  min-height: 40px;
  display: flex !important;
  align-items: center;
}

.fc-datagrid-cell-frame {
  display: flex;
  align-items: center;
  .fc-datagrid-cell-cushion .fc-scrollgrid-sync-inner {
    padding: 0 !important;
  }
  .fc .fc-datagrid-cell-cushion {
    padding: 0 !important;
  }
}

.fc .fc-datagrid-cell-cushion {
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.fc-datagrid-cell-frame {
  width: 100%;
  overflow: hidden;
}

.fc-datagrid-cell-main {
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  line-height: normal;
}

///// tabs
.tabs {
  .MuiTab-textColorPrimary {
    color: var(--joy-palette-neutral-800);
    &.Mui-selected {
      color: var(--joy-palette-neutral-800);
    }
  }
  .MuiTabs-indicator {
    background-color: var(--joy-palette-neutral-800);
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

// labelModalList
.labelSetting {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  .MuiFormControlLabel-label {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 20px !important;
  }
}

//
.section {
  padding: 18px;
}

//
.MuiPaper-rounded {
  border-radius: var(--border-radius-global) !important;
}

.columnheaderStartDay {
  cursor: pointer;
  &:hover {
    .fc-datagrid-cell-main {
      text-decoration: underline;
    }
  }
}

.twitter-picker {
  background: inherit !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  position: relative !important;
}

.has-hidden-metric {
  position: relative;
  &:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    content: '\22EE';
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    z-index: 0;
  }
}
