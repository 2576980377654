.main {
  width: 100%;
  height: 100vh;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-direction: column;
  background-color: var(--main-content-bg);
  padding: 20px;
  &__notFound {
    font-size: 1em;
  }
}
