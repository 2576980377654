.totalsSelect {
  color: #0a0a0a !important;
  button {
    background-color: inherit;
    &:hover {
      background-color: inherit;
    }
  }
}

.rangeDatePicker {
  div{
    border-radius: 8px !important;
  }
  input {

    padding: 8px;
  }
}

.chartTooltip {
  border-radius: 8px !important;
  font-size: 12px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  span {
    font-weight: 600;
  }


.chartTooltip_titleWrapper {
  display: flex;
  align-items: center;
  gap: 3px;
  .chartTooltip_color {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 2px;
    }
  }
}

.countChart {
  &_content {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 48px;
    white-space: nowrap;
    //span {
    //  position: absolute;
    //  right: 0;
    //  bottom: 0 ;
    //  line-height: 0;
    //  width: 100%;
    //  height: 100%;
    //  font-size: 100%
    //}
  }
}



.gridContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  gap: 18px;
  grid-auto-flow: dense;
}

.item1 {
  grid-column: 1 / span 6;
  grid-row: 1;
}
.item2 {
  grid-column: 1 / span 6;
  grid-row: 2;
}

.item3 {
  grid-column: 1 / span 1;
  grid-row: 4;
}

.item4 {
  grid-column: 2 / span 1;
  grid-row: 4;
}

.item5 {
  grid-column: 3 / span 1;
  grid-row: 4;
}

.item6 {
  grid-column: 4 / span 3;
  grid-row: 4;
}

.item7 {
  grid-column: 1 / span 3;
  grid-row: 3;
}

.item8 {
  grid-column: 4 / span 3;
  grid-row: 3;
}

.item9 {
  grid-column: 1 / span 6;
  grid-row: 5;
}



@media screen and(max-width: 1530px) {
  .countChart {
    &_content {
      font-size: 38px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .item1 {
    grid-column: 1 / span 6;
    grid-row: 1;
  }

  .item2 {
    grid-column: 1 / span 6;
    grid-row: 2;
  }

  .item3 {
    grid-column: 1 / span 2;
    grid-row: 5;
  }

  .item4 {
    grid-column: 3 / span 2;
    grid-row: 5;
  }

  .item5 {
    grid-column: 5 / span 2;
    grid-row: 5;
  }

  .item6 {
    grid-column: 1 / span 6;
    grid-row: 6;
  }

  .item7 {
    grid-column: 1 /  span 6;
    grid-row: 3;
  }

  .item8 {
    grid-column: 1 /  span 6;
    grid-row: 4;
  }

  .item9 {
    grid-column: 1 /  span 6;
    grid-row: 7;
  }
}