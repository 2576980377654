@import '../../../scss/variables';

.wrapper {
  height: 100%;
  width: 100%;
  min-width: 240px;
  padding: 0 16px 16px;

  transition: width 2s;
  .listItemText {
    transition: width 0.2s;
  }

  .accordionTitleIcon {
    svg {
      transform: translateY(-3px);
    }
  }

  //background-color:  var(--white-bg);
  :global(.MuiTypography-root){
    font-size: 0.9em !important;
  }

  :global(.MuiList-padding) {
    padding-top: 0;
  }

  li {
    padding-left: 0 !important;
    padding-right: 0 !important;
    :global(.Mui-expanded){
      background-color: var( --main-content-bg)!important;
      border-radius: var(--border-radius-global);
    }
  }

  :global(.MuiListItem-root) {
    :global(.MuiListItemIcon-root){
      min-width: 39px !important;
    }

    :global(.css-tlelie-MuiListItemText-root ){
      margin-bottom: 0 !important;
    }

    :hover {
      :global(.MuiListItemButton-root) {
        background-color: rgba(0, 0, 0, 0) !important;
      }
    }

    :global(.MuiPaper-root) {
      box-shadow: none !important;
      width: 100% !important;
      :global(.Mui-expanded) {
        :global(.MuiTouchRipple-root) {
          //background-color: rgba(255, 255, 255, 0.25);
        }
      }
    }
  }

  :global(.MuiAccordionDetails-root) {
    padding:0;

    ul {
      a {
        li {
          border-radius: var(--border-radius-global);
        }

      }
    }
    :global(.MuiList-root){
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
    :global(.MuiButtonBase-root) {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

.listItem {
  &:first-child{
    padding-top: 0;
  }
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  :global(.MuiListItemButton-root) {
    //background-color:  var(--joy-palette-neutral-800);
  }
  :global(.MuiPaper-elevation) {
    //background-color:  var(--joy-palette-neutral-800);
  }

  span,
  a {
    color: var(--joy-palette-neutral-800) !important;
  }
  :global(.MuiAccordionSummary-contentGutters) {
    margin: 0;
  }
  :global(.Mui-expanded) {
    margin: 0 !important;
  }

  :has(.activeSubTab) {
    background-color: var(--main-content-bg) !important;

    border-radius: var(--border-radius-global);
  }
  .activeSubTab {
    span {
      font-weight: 900 !important;
    }
  }
  .subTab {
    padding-left: 54px !important;
    padding-right: 16px !important;
    padding-top: 0;
    padding-bottom: 0;
    margin: 12px 0;
  }

  .activeSubTab.subTab {
    position: relative;
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 1px);
      left: 26px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--joy-palette-neutral-800) ;
    }
  }

  :global(.MuiCollapse-wrapper) {
    padding-bottom: 4px;
  }
}


.isCollapseWrapper {
  min-width: 69px;

  .subTab {
    padding-left: 54px !important;
    padding-right: 8px !important;
    padding-top: 0;
    padding-bottom: 0;
    margin: 12px 0;
  }
}
