$montserrat: 'Montserrat', sans-serif;

$white-bg: #fff;
$white-text: #fff;
$disabled-bg: #f3f4f5;
$disabled-border: #c2c8cc;
$disabled-text: #c2c8cc;
$input-text: #020f17;
$black: #000000;
$semi-gray-text: #888e92;
$gray-arrow: #979797;
$gray-light-shadow: rgba(224, 224, 224, 0.8);
$gray-dark-shadow: rgba(106, 117, 124, 0.15);
$gray-dark-bg: rgba(186, 234, 251, 0.1);

:root{
  --joy-palette-neutral-800: #262b40;
  --white-bg: #fff;
  --main-content-bg: #F6F9FE;
  --main-content-bg-second: #EFF3FC;
  --border-radius-global: 16px;

  //--joy-palette-neutral-800: #1E5860;
  //--white-bg: #fff;
  //--main-content-bg: #F5FDF7;
  //--border-radius-global: 16px;
}


