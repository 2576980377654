@import '../../../scss/variables';

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //background-color: var(--white-bg);
  padding: 0 16px;
}

.logo {
  color: var(--joy-palette-neutral-800);
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.25px;
}

.disabledLink {
  cursor: not-allowed;
  opacity: 0.5;
  a {
    color: currentColor;
    display: inline-block;
    pointer-events: none;
    text-decoration: none;
  }
}


.rangeDatePicker {
  div{
    border-radius: 8px !important;
  }
  input {
    padding: 8px;
  }
}