.settingsPopover {
  min-height: 39px;
  min-width: 38px;
  border-radius: 8px !important;

  svg {
    path {
      stroke: #3788d8;
    }
  }
  .filledBlue {
    fill: #3788d8;
  }

  &_iconButton {
    min-height: 39px;
    min-width: 38px;
    border-radius: 4px !important;
  }
}

.elevation {
  background-color: rgb(255, 255, 255);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 60px;
  letter-spacing: 0.01071em;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  display: inline-flex;
  margin: 5px;
  width: 390px;
  gap: 10px;
}

.reactImageVideoLightboxParent {
  :first-child {
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
}

.popperChangeMetric {
  &_wrapper {
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 18px;
    border-radius: 4px;
  }
  &_productList {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
  }
}

.popover {
  //border-radius: 8px !important;
}

.popoverResourceSettings {
  &_wrapper {
    display: flex;
    flex-direction: column;
    max-height: 350px;
    overflow-y: auto;
  }

  &_displayedMetricsWrapper {
    margin: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &_displayedMetricsChecbox{

  }

  &_colorWrapper {
    margin: 15px 15px 0 15px;
    z-index: 1;
  }

}
