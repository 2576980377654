.main {
  background-color: var(--main-content-bg);
  height: 100%;
  width: 100%;
}

.wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
}

.login {
  min-width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
  position: relative;
  gap: 20px;
  border-radius: var(--border-radius-global);
  background-color: var(--white-bg);
  -webkit-box-shadow: 0px 0px 16px -12px var(--joy-palette-neutral-800);
  -moz-box-shadow: 0px 0px 16px -12px var(--joy-palette-neutral-800);
  box-shadow: 0px 0px 16px -12px var(--joy-palette-neutral-800);
  &_wrapper {
    max-width: 400px;
  }

  input {
    padding: 12px!important;
  }
}

.formHelperText {
  position: absolute;
  bottom: -25%;
  left: 0;
}

.inputLabel {
  font-size: 14px;
  line-height: 24px;
  color: #9fa2b4;
  font-style: normal;
  font-weight: 700;
  letter-spacing: .4px;
}


.emailResetModal {
  min-width: 310px;
  padding: 20px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  gap: 20px;
  .content {
    min-width: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    gap: 20px;
  }
}