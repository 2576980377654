.wrapper {
  position: relative;
}

.img {
  object-fit: cover;
  object-position: center;
  max-width: 100%;
  width: auto;
  max-height: 100%;
  height: auto;
  margin: 0 auto;
}


.deleteIcon{
  margin: 5px 5px 0;
  cursor: pointer;
  fill: #d32f2f;
}

