button,
hr,
input {
  overflow: visible;
}

progress,
sub,
sup {
  vertical-align: baseline;
}

[type='checkbox'],
[type='radio'],
legend {
  box-sizing: border-box;
  padding: 0;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-weight: 400;
  font-style: normal;
  //color: #0a0a0a;
  line-height: 1.5;
}
p {
  font-size: 1.125em;
}

details,
main {
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
select {
  text-transform: none;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
  outline: ButtonText dotted 1px;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

[hidden],
template {
  display: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:before,
:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

a,
input,
label,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover,
a:focus {
  outline: none;
  text-decoration: none;
}

button,
input,
img,
textarea {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='checkbox'] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
}

input[type='radio'] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
}

img {
  display: block;
}

input:focus {
  outline: none;
}

input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='text']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
