.appWrapper {
  height: 100%;
  display: grid;
  grid-template-columns: fit-content(300px) 1fr;
  overflow: auto;
  position: relative;
}

.mainContent {
  overflow: auto;
  position: relative;
  height: 100%;
  width: 100%;
  background: var(--main-content-bg);
  border-top-left-radius: var(--border-radius-global);
}
